/* General Styles */

:root {
  --primary-color: #ffcc00;
  --background-dark: #1a1a1a;
  --background-light: #2e2e2e;
  --text-color: #ffffff;
  --text-muted: #cccccc;
  --font-family: 'Montserrat', sans-serif;
  --highlight-color: #ffd633;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  background-color: var(--background-dark);
  color: var(--text-color);
  line-height: 1.6;
}

/* Header */

.maintenance-container {
  padding: 150px 20px;
  text-align: center;
  background-color: var(--background-light);
  color: var(--text-color);
}

.maintenance-container h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: var(--primary-color);
  font-weight: bold;
}

.maintenance-container p {
  font-size: 1.1rem;
  color: var(--text-muted);
}

/* Content Area */

.content-container {
  padding: 40px 20px;
  max-width: 900px;
  margin: 0 auto;
  background-color: var(--background-dark);
  color: var(--text-color);
  border-radius: 8px;
}

.terms-content h2 {
  font-size: 1.8rem;
  margin-top: 30px;
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 10px;
  font-weight: bold;
}

.terms-content p {
  font-size: 1rem;
  margin-bottom: 20px;
  line-height: 1.8;
  color: var(--text-muted);
}

.terms-content ul {
  list-style: disc;
  padding-left: 20px;
}

.terms-content ul li {
  margin-bottom: 10px;
  font-size: 1rem;
  color: var(--text-color);
}

/* Footer Styles */

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  background-color: var(--background-light);
  padding: 40px 20px;
  color: var(--text-muted);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.footer-column {
  flex: 1;
  margin: 15px;
  min-width: 220px;
  max-width: 300px;
}

.footer-column h4 {
  font-size: 1.3rem;
  margin-bottom: 20px;
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin: 12px 0;
}

.footer-column ul li a {
  text-decoration: none;
  color: var(--text-muted);
  font-weight: 400;
  transition: color 0.3s ease;
}

.footer-column ul li a:hover {
  color: var(--highlight-color);
}

.social-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.social-links a {
  text-decoration: none;
  color: var(--text-color);
  font-size: 1.1rem;
  transition: color 0.2s ease;
}

.social-links a:hover {
  color: var(--highlight-color);
}

/* Bottom Bar */

.bottom-bar {
  width: 100%;
  background-color: var(--background-dark);
  padding: 15px;
  text-align: center;
  color: #ccc;
  font-size: 0.9rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.bottom-bar-text {
  max-width: 600px;
  margin: 0 auto;
  color: var(--text-muted);
}

/* Buttons */

.button {
  background-color: var(--primary-color);
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  color: var(--background-dark);
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.button:hover {
  background-color: var(--highlight-color);
}

/* Responsive Styles */

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-column {
    margin: 20px 0;
    width: 100%;
  }

  .social-links {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
  }

  .social-links a {
    margin-right: 15px;
  }

  h1, h2 {
    font-size: 2rem;
  }

  p, .footer-column ul li {
    font-size: 0.9rem;
  }

  .bottom-bar-text {
    font-size: 0.8rem;
  }
}

/* Countdown Timer Styles */

.countdown {
  display: flex;
  gap: 15px;
  margin: 20px 0;
  font-size: 1.5rem;
  justify-content: center;
  align-items: center;
}

.countdown-item {
  background-color: var(--primary-color);
  color: var(--background-dark);
  padding: 15px 20px;
  border-radius: 10px;
  font-weight: bold;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  min-width: 60px;
}

@media (max-width: 768px) {
  .countdown {
    gap: 10px;
    font-size: 1.2rem;
  }

  .countdown-item {
    padding: 10px 15px;
    border-radius: 8px;
    min-width: 50px;
  }
}


/* Redirecting Message Styles */
.redirecting-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.redirecting-container h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.redirecting-container p {
  font-size: 1.2rem;
}

.redirecting-container a {
  color: #007bff;
  text-decoration: none;
}

.redirecting-container a:hover {
  text-decoration: underline;
}